import { LINKS } from '@utils/constants';
import { IExpert } from '@utils/types';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function ExpertTemplate({
	data,
	pageContext: {
		breadcrumb: { crumbs },
	},
}: {
	data: {
		markdownRemark: {
			frontmatter: IExpert;
			html: string;
		};
		banner: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
	};
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
}) {
	const { t } = useTranslation();

	const { markdownRemark, banner } = data;
	if (!markdownRemark) return null;

	const { frontmatter, html } = markdownRemark;

	const avatar = getImage(frontmatter.image?.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={`${frontmatter.name} | ${t("_MeetTheTeam")} | Realis Simulation`}
				description=""
			/>

			<Banner
				title={frontmatter.name}
				image={{
					src: banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection layout="wide">
				<div className="profile-wrapper">
					<div className="col xl3 offset-xl1 m4 offset-m0 s12 s-spacer expert__aside">
						{avatar && (
							<div className="profile-avatar">
								<div className="image-background">
									<GatsbyImage
										image={avatar}
										alt={frontmatter.name}
									/>
								</div>
							</div>
						)}
						<div className="profile-contact">
							<Button to={LINKS.ABOUT_TEAM} type="more">
								{t("_OtherExperts")}
							</Button>
						</div>
					</div>
					<div className="col xl6 offset-xl1 l7 offset-l0 m8 s12 s-spacer expert__content">
						<header className="expert__header">
							<h1 className="profile-name">{frontmatter.name}</h1>
							<span className="profile-job">
								{frontmatter.summary}
							</span>
						</header>
						<div className="profile-bio"
							dangerouslySetInnerHTML={{ __html: html }}
						/>
						{frontmatter.linkedin && (
							<aside className="share__icons">
								<span>{t("_FullBio")}</span>
								<a
									target="_blank"
									rel="noreferrer"
									href={`${frontmatter.linkedin}`}
									className="social-icon__item social-icon--linkedin">
								</a>
							</aside>
						)}
					</div>
				</div>
			</PageSection>
		</Page>
	);
}

export const pageQuery = graphql`
	query ($language: String!, $slug: String!) {
		locales: allLocale(
			filter: { ns: { in: ["_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(
      		fields: { language: { eq: $language }, slug: { eq: $slug } }
		) {
			frontmatter {
				name
				summary
				image {
					...imageStandard
				}
				linkedin
			}
			html
		}
		banner: file(relativePath: { eq: "content/about-us/meet-the-team/Realis-Simulation_MeetTheTeam_cropped.jpg" }) {
			...imageBreaker
		}
	}
`;
